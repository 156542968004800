import { useEffect, useState } from 'react';

export const useInView = (ref) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsInView(entry.isIntersecting);
        },
        { threshold: 0.9 } // Adjust this threshold to define visibility range
      );

      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref]);

  return isInView;
};
