import React from 'react';
import { motion } from 'framer-motion';
import buyBullyPhoto from './../assets/images/bully_2.jpg'

const Tokenomics = () => {
  return (
    <motion.section
      className="py-20 flex flex-col items-center justify-center bg-zinc-900 text-white"
      initial={{ opacity: 0, x: -0 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.7 }}
    >
        <h1 className="text-5xl font-bold mb-8 text-amber-600 text-center">TOKENOMICS</h1>
        <h2 className="text-2xl text-center">Bully made a 4.2 billion supply with 100% fair launch no pre mint.</h2>

        <br/><br/><br/>
        
            
        <div className="flex flex-col md:flex-row container">
            {/* Left Half: Image */}
            <div className="w-full md:w-1/2 flex items-center justify-center p-4">
                <img src={buyBullyPhoto} alt="Example" className="w-full h-auto object-cover max-h-[80vh] rounded-lg shadow-lg max-w-lg" />
            </div>

            {/* Right Half: Text */}
            <div className="w-full md:w-1/2 flex flex-col items-center justify-center text-center p-8 text-center">
                <div className="w-full bg-kaspa text-black p-4 rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-lg text-center cursor-pointer">
                    <h3 className="text-3xl font-black mb-2">
                        TOTAL SUPPLY
                    </h3>
                    <p className="text-xl font-black">
                        4,200,000,000 $BULLY
                    </p>
                </div>
                <br/>
                <div className="w-full bg-kaspa text-black p-4 rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-lg text-center  cursor-pointer">
                    <h3 className="text-2xl font-black p-4">
                        100% FAIR LAUNCH
                    </h3>
                </div>
            </div>
        </div>
        
    </motion.section>
  );
};

export default Tokenomics;
