// src/components/Roadmap.js
import React from 'react';
import { motion } from 'framer-motion';

const roadmapData = [
  {
    title: 'Development of Mobile Application',
    description: 'Featuring wallet integration and a bot.',
    status: 'In Progress',
  },
  {
    title: 'Launch of Lottery Game',
    description:
      'Launch a LOTTERY GAME with potential rewards in Kaspa coins, allocating 5% of proceeds to the community fund.',
    status: 'Upcoming',
  },
  {
    title: 'Interactive Telegram Game',
    description: 'Create an interactive Telegram game to enhance community participation.',
    status: 'Upcoming',
  },
  {
    title: 'New Listings',
    description:
      'Facilitation of new listings on centralized (CEX) and decentralized exchange (DEX).',
    status: 'Planned',
  },
  {
    title: 'Introduction of Future NFTs',
    description: 'Expand our offerings with innovative NFTs.',
    status: 'Planned',
  },
  {
    title: 'BULLY Merchandise',
    description: 'Development of BULLY merchandise and additional initiatives.',
    status: 'Planned',
  },
];

const Roadmap = () => {
  return (
    <motion.section
      className="py-20 px-6 bg-[#6fc7ba] text-gray-800"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <h1 className="text-5xl font-bold text-center mb-12 font-handwriting text-amber-600">
        ROADMAP
      </h1>

      <div className="relative w-full max-w-4xl mx-auto">
        {/* Vertical Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-300"></div>

        <div className="flex flex-col space-y-12">
          {roadmapData.map((item, index) => (
            <motion.div
              key={index}
              className={`relative flex ${
                index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
              } items-center`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              {/* Connector Circle */}
              <div className="absolute left-1/2 transform -translate-x-1/2 w-6 h-6 bg-white border-2 border-gray-500 rounded-full"></div>

              {/* Content Box */}
              <div
                className={`flex flex-col w-[90%] md:w-[40%] bg-white shadow-lg rounded-lg p-6 ${
                  index % 2 === 0 ? 'ml-8' : 'mr-8'
                }`}
              >
                <h2 className="text-xl font-bold mb-2">{item.title}</h2>
                <p className="text-gray-600 mb-3">{item.description}</p>
                <span
                  className={`inline-block px-3 py-1 text-sm font-semibold rounded-full ${
                    item.status === 'In Progress'
                      ? 'bg-yellow-100 text-yellow-700'
                      : item.status === 'Upcoming'
                      ? 'bg-blue-100 text-blue-700'
                      : 'bg-green-100 text-green-700'
                  }`}
                >
                  {item.status}
                </span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Roadmap;
