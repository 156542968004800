import React from 'react';
import { motion } from 'framer-motion';
import buyBullyPhoto from './../assets/images/bully_1.png'

const HowToBuy = () => {
  return (
    <motion.section
      className="py-20 flex flex-col items-center justify-center bg-kaspa text-white font-handwriting"
      initial={{ opacity: 0, x: -0 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.7 }}
    >
        <h1 className="text-6xl font-bold mb-8 text-amber-600 text-center">HOW TO BUY</h1>
        <h2 className="text-2xl text-center">Follow these easy steps to become a Bully holder.</h2>

        <br/><br/><br/>

        {/* Card Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-12">
        
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        
                {/* First Card */}
                <div className="bg-black text-white p-4 rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-sm">
                    <h3 className="text-xl font-bold mb-2">
                        <span class="text-amber-600">1. </span>
                        <span>Get your Kaspa</span>
                    </h3>
                    <p className="text-md">
                        Buy Kaspa on your favorite exchanges.
                    </p>
                </div>

                {/* Second Card */}
                <div className="bg-black text-white p-4 white rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-sm">
                    <h3 className="text-xl font-bold mb-2">
                        <span class="text-amber-600">2. </span>
                        <span>Create a wallet</span>
                    </h3>
                    <p className="text-md">
                        Create a Kaspa wallet using KSPR telegram bot.
                    </p>
                </div>

                {/* Third Card */}
                <div className="bg-black text-white p-4 rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-sm">
                    <h3 className="text-xl font-bold mb-2">
                        <span class="text-amber-600">3. </span>
                        <span>Transfer Kaspa</span>
                    </h3>
                    <p className="text-md">
                        Transfer the Kaspa that you bought on exchanges to your KSPR bot wallet. 
                    </p>
                </div>

                {/* Fourth Card */}
                <div className="bg-black text-white p-4 rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-sm">
                    <h3 className="text-xl font-bold mb-2">
                        <span class="text-amber-600">4. </span>
                        <span>Swap your $KAS for $BULLY</span>
                    </h3>
                    <p className="text-md">
                        Buy your BULLY in the KSPR marketplace.
                    </p>
                </div>
            </div>
        
            <div className="w-full flex items-center justify-center">
                <div class="relative w-80 h-80">
                {/* <img
                    src={buyBullyPhoto}  // Replace with your image URL
                    alt="Background"
                    className="object-cover w-full h-full"
                /> */}
                <motion.img
                    src={buyBullyPhoto}
                    alt="How to Buy"
                    className="object-cover w-full h-full cursor-pointer"
                    initial={{ scale: 1 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.5 }}
                />
                </div>
            </div>

      </div>
        
    </motion.section>
  );
};

export default HowToBuy;
