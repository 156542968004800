// src/components/SectionOne.js
import React, { forwardRef } from 'react';
import bullyNav from '../assets/images/bully-nav-logo.png';
import bullyFront from '../assets/images/bully-home-logo.png';
import kaspa from './../assets/images/socials/kaspa-2.png';
import telegram from './../assets/images/socials/telegram-2.png';
import xSocials from './../assets/images/socials/x.png';
import { motion } from 'framer-motion';

const SectionOne = forwardRef((props, ref) => {
  return (
    <motion.section
      ref={ref}
      id="section1"
      className="flex flex-col bg-kaspa font-handwriting w-full"
    >
      {/* Logo Section */}
      <div className="p-10 flex hidden md:flex">
        <img src={bullyNav} alt="Logo" className="h-16 w-30" /> 
      </div>



      {/* Main Content Section */}
      <div className="flex flex-col items-center justify-center pt-32 md:pt-0">
        <div className="w-full h-auto max-w-lg md:h-[35rem]">
          <img src={bullyFront} alt="Bully" className="w-full h-full object-contain" />
        </div>

        {/* Text Section */}
        <div className="text-2xl font-black text-center mb-8">
          The only good bully you'll encounter
        </div>
      </div>

      


        {/* Icons */}
        <div className="flex px-10 pb-8 space-x-3 items-center md:items-start">
          {[
            { link: 'https://x.com/Bullykas', img: xSocials, alt: 'X (Twitter)' },
            { link: 'https://t.me/bully_kas', img: telegram, alt: 'Telegram' },
            { link: 'https://kas.fyi/token/krc20/BULLY', img: kaspa, alt: 'Kaspa' },
          ].map((social, index) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noreferrer"
              className="group transform hover:scale-110 transition-all duration-300"
            >
              <motion.img
                src={social.img}
                alt={social.alt}
                className="w-14 h-14 md:w-16 md:h-16 object-contain shadow-md group-hover:shadow-lg rounded-full bg-white p-2"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              />
            </a>
          ))}
        </div>
    </motion.section>
  );
});

export default SectionOne;
